<template>
  <div>
    <div
      class="d-flex flex-wrap pa-3 white"
      :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''"
    >
      <div class="d-flex flex-wrap">
        <v-autocomplete
          v-model="query.start_year"
          :items="schoolYearList"
          :item-text="(e) => `${e.start_year} / ${e.end_year}`"
          item-value="start_year"
          outlined
          dense
          hide-details
          placeholder="Tahun Ajaran"
          class="mr-2"
        />
        <v-autocomplete
          v-model="query.classroom"
          :class="!$vuetify.breakpoint.xsOnly ? 'mr-2' : ''"
          :items="classList"
          :item-text="(e) => `${e.grade_name}-${e.name}`"
          item-value="id"
          outlined
          dense
          hide-details
          placeholder="Kelas"
        />
        <div class="d-flex align-center">
          <v-text-field
            v-model="query.search"
            placeholder="Cari data"
            append-icon="mdi-magnify"
            dense
            outlined
            hide-details
            clearable
          />
          <v-btn
            color="secondary"
            class="ml-2"
            depressed
            @click="getStudentHandler"
            >Cari</v-btn
          >
        </div>
      </div>

      <v-spacer />

      <div>
        <v-btn
          v-if="role === 'ADMIN'"
          color="primary"
          class="mr-2"
          depressed
          @click="showSchoolYearForm()"
        >
          Tambah siswa
        </v-btn>

        <v-menu absolute offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" fab depressed small color="grey"
              ><v-icon>mdi-file-excel-box</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item>
              <ButtonUploadExcel
                label="Tambah Siswa Excel"
                :onSuccess="uploadExcelHandler"
              />
            </v-list-item>
            <v-list-item>
              <v-btn
                href="/files/Template Master Siswa.xlsx"
                class="text-capitalize"
                color="info"
                outlined
                download
                block
              >
                Download Template Excel
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <!-- Header -->

    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
    >
      <template v-slot:[`item.class_name`]="{ item }">
        {{ item.grade_name }}-{{ item.class_name }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          color="primary"
          fab
          depressed
          x-small
          @click="showSchoolYearForm(item)"
          ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <MasterStudentForm
      :dialog="dialogForm"
      :selectedData="selectedData"
      :closeDialog="(param) => closeForm(param)"
    />

    <MasterStudentUploadExcel
      :dialog="excelDialog"
      :data="excelData"
      :classList="classList"
      :closeDialog="(param) => closeFormExcel(param)"
    />

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Hapus siswa ${deleteData && deleteData.name}?`"
      title="Hapus Siswa"
      @action="(r) => deleteAction(r)"
    />
  </div>
</template>

<script>
import { listSchoolYear } from "@/api/admin/schoolYear";
import { getClass } from "@/api/admin/class";
import { getStudent, deleteStudent } from "@/api/admin/student";

import ButtonUploadExcel from "@/components/ButtonUploadExcel.vue";
import MasterStudentForm from "./MasterStudentForm.vue";
import MasterStudentUploadExcel from "./MasterStudentUploadExcel.vue";

export default {
  components: {
    ButtonUploadExcel,
    MasterStudentForm,
    MasterStudentUploadExcel,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      dialogConfrim: false,
      dialogForm: false,
      schoolYearList: [],
      classList: [],
      options: {},
      total: 0,
      query: {
        start_year: "",
        search: "",
        limit: 5,
        page: 1,
        classroom: null,
      },
      data: [],
      excelDialog: false,
      excelData: {},
      selectedData: {},
      deleteData: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nama",
          value: "name",
        },
        {
          text: "NIS",
          value: "nis",
        },
        {
          text: "Kelas",
          value: "class_name",
        },
        {
          text: "Aksi",
          value: "action",
          align: "center",
          width: 120,
        },
      ];
    },
    role() {
      return this.$store.getters.g_roles;
    },
  },
  watch: {
    options: {
      handler(param) {
        this.query.page = param.page;
        this.query.limit = param.itemsPerPage;

        this.getStudentHandler();
      },
      deep: true,
    },
  },
  async created() {
    await listSchoolYear().then((res) => {
      if (res.data.code) {
        this.schoolYearList = res.data.data;
        this.schoolYearList.map((s) => {
          if (s.is_active) this.query.start_year = s.start_year;
        });
      }
    });
    await getClass().then((res) => {
      if (res.data.code) {
        this.classList = res.data.data;
      }
    });
    this.getStudentHandler();
  },
  methods: {
    uploadExcelHandler(data) {
      this.excelData.headers = data.header;
      this.excelData.data = data.results;

      if (data.results.length > 0) {
        this.excelDialog = true;
      } else {
        this.$store.commit("SET_SNACKBAR", {
          value: true,
          text: "Data Siswa Kosong, Silahan isi data terlebih dahulu",
          color: "error",
        });
      }
    },
    closeForm(param) {
      if (param === "save") this.getStudentHandler();
      this.dialogForm = false;
      this.selectedData = {};
    },
    closeFormExcel(param) {
      if (param === "save") this.getStudentHandler();
      this.excelDialog = false;
      this.excelData = {};
    },
    getStudentHandler() {
      this.loading = true;
      getStudent(this.query)
        .then((res) => {
          if (res.data.code) {
            this.data = res.data.data.data;
            this.total = res.data.data.total;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    showSchoolYearForm(item) {
      this.dialogForm = true;
      if (item) {
        this.selectedData = item;
      }
    },
    deleteHandler(item) {
      this.deleteData = item;
      this.dialogConfrim = true;
    },
    deleteAction(param) {
      if (param) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
        deleteStudent({ id: this.deleteData.id })
          .then((res) => {
            if (res.data.code) {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: "Berhasil menghapus siswa",
                color: "success",
              });
              this.getStudentHandler();
              this.deleteData = null;
              this.dialogConfrim = false;
            } else {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: res.data.message,
                color: "error",
              });
            }
            this.$store.commit("SET_GLOBAL_LOADING", false);
          })
          .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
      } else {
        this.dialogConfrim = false;
      }
    },
  },
};
</script>
