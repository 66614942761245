import request from "@/utils/request";

export function getStudent(data) {
  return request({
    method: "post",
    url: "/student/list",
    data,
  });
}

export function addStudent(data) {
  return request({
    method: "post",
    url: "/student/add",
    data,
  });
}

export function editStudent(data) {
  return request({
    method: "post",
    url: "/student/edit",
    data,
  });
}

export function detailStudent(data) {
  return request({
    method: "post",
    url: "/student/detail",
    data,
  });
}

export function deleteStudent(data) {
  // Belum ada api
  return request({
    method: "post",
    url: "/student/delete",
    data,
  });
}

export function uploadStudent(data) {
  return request({
    method: "post",
    url: "/student/upload",
    data,
  });
}
