<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card flat>
      <v-card-title class="grey">
        <div class="heading-4 font-weight-bold">
          {{ selectedData && selectedData.id ? "Edit Siswa" : "Tambah Siswa" }}
        </div>
        <v-spacer></v-spacer>
        <v-icon class="font-weight-bold" @click="closeHandler"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pa-4">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row class="d-flex align-stretch">
            <v-col cols="12" sm="6">
              <div v-show="form.photo">
                <vueCropper
                  ref="cropper"
                  :img="form.photo"
                  :autoCrop="true"
                  :canScale="true"
                  :fixedBox="true"
                  :canMoveBox="false"
                  autoCropWidth="200"
                  autoCropHeight="200"
                  class="upload-wrapper"
                ></vueCropper>
                <v-btn
                  block
                  depressed
                  color="error"
                  class="mt-2"
                  @click="removeFile"
                  ><v-icon>mdi-delete</v-icon> Hapus Foto</v-btn
                >
              </div>

              <ButtonUpload
                v-show="!form.photo"
                :loading="loadingUpload"
                @getDocument="(e) => (form.photo = e)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    :rules="[(v) => !!v || 'Nama harus diisi']"
                    label="Nama"
                    autocomplete="off"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.number="form.nis"
                    :rules="[(v) => !!v || 'NIS harus diisi']"
                    label="NIS"
                    autocomplete="off"
                    type="number"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.class"
                    :items="classList"
                    :item-text="(e) => `${e.grade_name}-${e.name}`"
                    item-value="id"
                    :rules="[(v) => !!v || 'Kelas harus diisi']"
                    label="Kelas"
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="!(selectedData && selectedData.id)" cols="12">
                  <v-text-field
                    v-model="form.password"
                    :rules="[
                      (v) => !!v || 'Password harus diisi',
                      (v) =>
                        (v && v.length > 7) || 'Password minimal 8 karakter',
                    ]"
                    type="password"
                    label="Password"
                    autocomplete="off"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-space-between">
              <v-btn outlined color="secondary" @click="closeHandler"
                >Kembali</v-btn
              >
              <v-btn
                :disabled="loadingUpload"
                color="primary"
                depressed
                @click="submit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { addStudent, detailStudent, editStudent } from "@/api/admin/student";
import { getClass } from "@/api/admin/class";
import { uploadFile, removeFile } from "@/api/file";

import ButtonUpload from "@/components/ButtonUpload";
import { VueCropper } from "vue-cropper";

export default {
  components: {
    ButtonUpload,
    VueCropper,
  },
  props: {
    dialog: Boolean,
    selectedData: Object,
    closeDialog: Function,
  },
  data() {
    return {
      valid: false,
      loadingUpload: false,
      classList: [],
      deleteFile: null,
      form: {
        photo: "",
        name: "",
        nis: "",
        class: "",
        password: "",
      },
      detail: {},
    };
  },
  watch: {
    async dialog(n) {
      if (n && this.selectedData && this.selectedData.id) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
        await detailStudent({ id: this.selectedData.id }).then((res) => {
          if (res.data.code) this.detail = res.data.data;
        });
        this.$store.commit("SET_GLOBAL_LOADING", false);

        this.form = {
          photo: this.detail.user.avatar,
          name: this.detail.person.name,
          nis: this.detail.student.nis,
          class: this.detail.student.classroom.id,
        };
      }
    },
  },
  mounted() {
    getClass().then((res) => {
      if (res.data.code) {
        this.classList = res.data.data;
      }
    });
  },
  methods: {
    checkUrlString(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return !!pattern.test(str);
    },
    removeFile() {
      if (this.selectedData && this.selectedData.id) {
        this.deleteFile = this.form.photo;
      }

      setTimeout(() => {
        this.form.photo = null;
      }, 100);
    },
    closeHandler(param) {
      this.$refs.form.reset();
      this.form.photo = "";
      this.closeDialog(param);
    },
    getCropImage() {
      if (this.$refs.cropper) {
        const croped = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.cropedImage(croped);
      }
    },
    dataURLtoFile(dataurl, filename) {
      if (dataurl) {
        let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const f = { ...this.form };
        this.$store.commit("SET_GLOBAL_LOADING", true);
        let type = "add";
        if (this.selectedData && this.selectedData.id) type = "edit";

        if (f.photo) {
          let body = new FormData();
          this.$refs.cropper.getCropData(async (fileCrop) => {
            if (fileCrop) {
              const file = this.dataURLtoFile(fileCrop, `${f.name}.jpeg`);
              body.append("file", file);
            }

            const res = await uploadFile(body);
            const r = res.data;
            if (r.code) f.photo = r.data.path;

            this.actionData(f, type);
          });
        } else {
          this.actionData(f, type);
        }
      }
    },
    async actionData(f, type) {
      let query = {};
      switch (type) {
        case "add":
          query = {
            user: {
              avatar: f.photo,
              password: f.password,
            },
            person: {
              name: f.name,
            },
            student: {
              nis: f.nis,
              classroom: {
                id: f.class,
              },
            },
          };

          addStudent(query)
            .then((res) => {
              if (res.data.code) {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: "Berhasil menambah siswa",
                  color: "success",
                });
                this.closeHandler("save");
              } else {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: res.data.message,
                  color: "error",
                });
              }
              this.$store.commit("SET_GLOBAL_LOADING", false);
            })
            .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
          break;
        case "edit":
          if (this.checkUrlString(this.deleteFile)) {
            await removeFile({ file: this.deleteFile }).then((res) => {
              console.log(res);
            });
          }

          query = { ...this.detail };
          query.user.avatar = f.photo;
          query.person.name = f.name;
          query.student.nis = f.nis;
          query.student.classroom.id = f.class;

          editStudent(query)
            .then((res) => {
              if (res.data.code) {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: "Berhasil merubahs siswa",
                  color: "success",
                });
                this.closeHandler("save");
              } else {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: res.data.message,
                  color: "error",
                });
              }
              this.$store.commit("SET_GLOBAL_LOADING", false);
            })
            .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
          break;
        default:
          break;
      }
    },
  },
};
</script>
