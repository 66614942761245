<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card flat>
      <v-card-title class="grey">
        <div class="heading-4 font-weight-bold">Tambah Data Siswa</div>
        <v-spacer></v-spacer>
        <v-icon class="font-weight-bold" @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-data-table :headers="headers" :items="dataDisplay">
          <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.grade_id`]="{ item }">
            <v-btn v-if="item.grade_id_valid" depressed color="white">{{
              item.grade_id
            }}</v-btn>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="error" dark v-bind="attrs" v-on="on">
                  {{ item.grade_id }}
                </v-btn>
              </template>
              <span
                >Pastikan kode tingkatan sesuai dengan master kelas yang
                terdaftar</span
              >
            </v-tooltip>
          </template>
          <template v-slot:[`item.class_name`]="{ item }">
            <v-btn v-if="item.class_name_valid" depressed color="white">{{
              item.class_name
            }}</v-btn>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="error" dark v-bind="attrs" v-on="on">
                  {{ item.class_name }}
                </v-btn>
              </template>
              <span
                >Pastikan nama kelas sesuai dengan master kelas yang
                terdaftar</span
              >
            </v-tooltip>
          </template>
          <template v-slot:[`item.password`]="{ item }">
            {{ item.password ? item.password : "12345678" }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" large depressed @click="saveData">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { uploadStudent } from "@/api/admin/student";

export default {
  props: {
    dialog: Boolean,
    closeDialog: Function,
    data: Object,
    classList: Array,
  },
  data() {
    return {
      dataDisplay: [],
      dataError: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          value: "no",
        },
        {
          text: "Nama Siswa",
          value: "name",
        },
        {
          text: "NIS",
          value: "nis",
        },
        {
          text: "Kode Tingkatan",
          value: "grade_id",
        },
        {
          text: "Nama Kelas",
          value: "class_name",
        },
        {
          text: "Password",
          value: "password",
        },
      ];
    },
  },
  watch: {
    dialog() {
      const dataError = [];
      const dataDisplay = [];
      const data = this.data.data;
      data.map((row, index) => {
        const gradeValidation =
          this.validationData(row.kode_tingkatan, "grade_id") >= 0;
        const classNameValidation =
          this.validationData(row.nama_kelas, "class_name") >= 0;

        const data = {
          nis: row.nis,
          name: row.nama_siswa,
          grade_id_valid: gradeValidation,
          grade_id: row.kode_tingkatan,
          class_name_valid: classNameValidation,
          class_name: row.nama_kelas,
          password: row.password ? row.password : "12345678",
        };

        // All Data
        dataDisplay.push(data);

        // Only dont have valid data
        if (!gradeValidation || !classNameValidation) {
          dataError.push({ no: index + 1, ...data });
        }
      });

      this.dataError = dataError;
      this.dataDisplay = dataDisplay;
    },
  },
  methods: {
    validationData(data, type) {
      let valid = false;
      if (type === "grade_id") {
        valid = this.classList.findIndex((e) => e.grade_id === data);
      } else if (type === "class_name") {
        valid = this.classList.findIndex((e) => e.name === data);
      }

      return valid;
    },
    saveData() {
      if (this.dataError.length > 0) {
        var errorNo = this.dataError.map((item) => item.no);
        this.$store.commit("SET_SNACKBAR", {
          value: true,
          text: `Data Siswa No: ${errorNo.toString()} tidak valid`,
          color: "error",
        });
      } else {
        this.$store.commit("SET_GLOBAL_LOADING", true);
        uploadStudent({ data: this.dataDisplay })
          .then((res) => {
            if (res.data.code) {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: "Berhasil menambah siswa",
                color: "success",
              });
              this.closeDialog("save");
            } else {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: res.data.message,
                color: "error",
              });
            }
            this.$store.commit("SET_GLOBAL_LOADING", false);
          })
          .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
      }
    },
  },
};
</script>
