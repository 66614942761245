<template>
  <div style="height: 100%">
    <div class="upload-wrapper" @click="onButtonClick">
      <div class="text-center">
        <v-icon size="43">mdi-image</v-icon>
        <div>Pilih Gambar</div>
      </div>
    </div>

    <input
      v-show="false"
      ref="upload"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
  </div>
</template>

<script>
export default {
  name: "ButtonUpload",
  watch: {
    reset(newValue) {
      if (newValue) {
        this.$refs.upload.value = "";
      }
    },
  },
  data() {
    return {
      loading: false,
      document: null,
    };
  },
  methods: {
    onButtonClick() {
      this.$refs.upload.click();
    },
    onFileChanged(e) {
      const self = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = function () {
        self.$emit("getDocument", reader.result);
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss">
.upload-wrapper {
  height: 100%;
  min-height: 200px;
  min-width: 200px;
  border-radius: 10px;
  background: var(--unnamed-color-f8f8f8) 0% 0% no-repeat padding-box;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #adadad;
  opacity: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
